import { z } from "zod";

import { IntoNat } from "@tracksuit/fundamental-types";

export const CategoryId = IntoNat.brand("categoryId");

export type CategoryId = z.infer<typeof CategoryId>;

export const CategoryName = z.string().brand("CategoryName");

export type CategoryName = z.infer<typeof CategoryName>;
export const CategoryGeographyId = IntoNat.brand("CategoryGeographyId");

export type CategoryGeographyId = z.infer<typeof CategoryGeographyId>;

export const Category = z.object({
  id: CategoryId,
  name: CategoryName,
});

export type Category = z.infer<typeof Category>;
