import { z } from "zod";

export const FilterType = z.string().brand("FilterType");

export type FilterType = z.infer<typeof FilterType>;

export const FilterTarget = z.enum(["FUNNEL", "STATEMENTS"]);

export type FilterTarget = z.infer<typeof FilterTarget>;

export const CustomFilterType = "Custom filter";

export const IncludeIncompleteMonthsSchema = z
  .string()
  .toLowerCase()
  .transform((x) => JSON.parse(x))
  .pipe(z.boolean())
  .optional()
  .default("false");
