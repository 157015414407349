import { useMemo } from "react";

import { useAvailableFilters } from "../queries/use-available-filters";

export const useRequiredDatesCount = (length: number) => {
  const { availableFilters } = useAvailableFilters();
  const sufficient = useMemo(() => {
    if (typeof availableFilters.dates === "undefined") {
      return;
    }

    return availableFilters.dates.length >= length;
  }, [availableFilters.dates]);

  return sufficient;
};
