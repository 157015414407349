import _ from "lodash";
import { z } from "zod";

export const Population = z.number().int().brand("Population");

export type Population = z.infer<typeof Population>;

export const DisplayPopulation = z.number().transform((num) => _.round(num, 0));

export type DisplayPopulation = z.infer<typeof DisplayPopulation>;
