import _ from "lodash";
import { z } from "zod";

export const SignedPercentage = z
  .number()
  .min(-1.0)
  .max(1.0)
  .transform((x) => Number(x.toFixed(4)));

export type SignedPercentage = z.infer<typeof SignedPercentage>;

export const ExtendedPercentage = z
  .number()
  .min(0.0)
  .transform((x) => Number(x.toFixed(4)));

export type ExtendedPercentage = z.infer<typeof ExtendedPercentage>;

export const DisplayPercentage = z.number().transform((num) => _.round(num, 2));

export type DisplayPercentage = z.infer<typeof DisplayPercentage>;
