import { cx } from "$src/lib/utils";
import { type ComponentProps } from "react";

import styles from "./checkbox.module.css";

export type CheckboxProps = {
  label: string;
  checked: boolean;
  disabled?: boolean;
  subtext?: string;
  onChange?(checked: boolean): void;
} & Omit<ComponentProps<"label">, "onChange">;

/**
 * @component
 * Checkbox input
 */
export const Checkbox = ({
  label,
  checked,
  disabled,
  onChange,
  subtext,
  className,
  ...props
}: CheckboxProps) => {
  return (
    <label className={cx(styles.checkbox, className)} {...props}>
      <input
        className={styles.input}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={({ target }) => onChange?.(target.checked)}
        data-testid="checkbox-input"
      />
      <svg viewBox="0 0 24 24" fill="none" className={styles.icon}>
        <rect x="2" y="2" width="20" height="20" rx="2" strokeWidth="1.5" />
        <path
          d="M 7,12.3409 10.34821,15.75 17.7143,8.25"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div className={styles.caption}>
        <div className={styles.label}>{label}</div>
        {subtext && <div className={styles.subtext}>{subtext}</div>}
      </div>
    </label>
  );
};
