import moment from "moment";
import { z } from "zod";

const formatDateToWaveDate = (date: Date): string => {
  return moment(date).format("YYYY-MM-DD");
};

const formatWaveDate = (date: string): string => {
  return moment(new Date(date)).format("YYYY-MM-DD");
};

export const WaveDate = z
  .string({ invalid_type_error: "Invalid wave date" })
  .regex(/^\d{4}-\d{2}-\d{2}$/)
  .brand("WaveDate");

export type WaveDate = z.infer<typeof WaveDate>;

export const IntoWaveDate = z
  .union([
    z.string().transform(formatWaveDate),
    z.date().transform(formatDateToWaveDate),
  ])
  .pipe(WaveDate);

export type IntoWaveDate = z.infer<typeof IntoWaveDate>;

export const WaveRange = z.object({
  start: WaveDate,
  end: WaveDate,
});
export type WaveRange = z.infer<typeof WaveRange>;
