import { PopoutArrow, usePopout } from "$src/hooks/use-popout";
import { cx } from "$src/lib/utils";
import mixins from "$src/styles/mixins.module.css";
import { type Placement } from "@floating-ui/react";
import { type ComponentProps, type ReactNode, useRef } from "react";

import styles from "./tooltip.module.css";

export type TooltipProps = {
  /** Content title for the tooltip */
  title?: ReactNode | string;
  /** Content for the tooltip */
  tip: ReactNode;
  /** Whether tooltip is disabled */
  disabled?: boolean;
  /** Placement of the tip */
  placement?: Placement;
  /** Whether tooltip should be compact sized */
  compact?: boolean;
  /** Option to increase tooltip max-width */
  large?: boolean;
} & ComponentProps<"div">;

export const Tip = ({
  compact,
  withArrow,
  title,
  tip,
  large = false,
  className,
  ...props
}: {
  compact?: boolean;
  withArrow?: boolean;
  title?: ReactNode | string;
  tip: ReactNode | string;
  large?: boolean;
} & ComponentProps<"div">) => {
  return (
    <div
      className={cx(
        styles.tip,
        large && styles["wide-tooltip"],
        compact && styles.compact,
        withArrow && styles["with-arrow"],
        mixins.richtext,
        className,
      )}
      {...props}
    >
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles["tip-content"]} data-testid="tip">
        {tip}
      </div>
      {withArrow && (
        <svg className={styles.arrow} width="20" height="8" viewBox="0 0 20 8">
          <path
            fill="var(--color-bg)"
            filter="drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1))"
            d="M11.91 7a2 2 0 0 1-2.5 0L.66 0h20l-8.75 7Z"
          />
        </svg>
      )}
    </div>
  );
};

/**
 * @component
 * Tooltip popup
 */
export const Tooltip = ({
  title,
  tip,
  disabled,
  placement = "top",
  large = false,
  compact,
  children,
  className,
  ...props
}: TooltipProps) => {
  const arrowRef = useRef(null) as any;
  const {
    open,
    context,
    refs,
    getReferenceProps,
    getFloatingProps,
    floatingStyles,
  } = usePopout({
    placement,
    arrowRef,
    interactions: { click: false, hover: true },
  });

  return (
    <div
      className={cx(styles.content, className)}
      ref={refs.setReference as any}
      data-testid="tooltip"
      {...getReferenceProps()}
      {...props}
    >
      {children}
      {!disabled && open && (
        <>
          <div
            style={{ ...floatingStyles, zIndex: "var(--layer-4)" }}
            data-x-hidden-from-screenshot
            data-testid="tooltip-tip"
            ref={refs.setFloating}
            {...getFloatingProps()}
          >
            <Tip large={large} compact={compact} title={title} tip={tip} />
            <PopoutArrow ref={arrowRef} context={context} />
          </div>
        </>
      )}
    </div>
  );
};
