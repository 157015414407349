import type { RouteObject } from "react-router-dom";

export const settingsRoutes: RouteObject["children"] = [
  {
    async lazy() {
      const { SettingsLayout: Component } = await import("./layout");
      return { Component };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { SettingsRoute: Component } = await import("./settings");
          return { Component };
        },
      },
      {
        path: "mfa",
        async lazy() {
          const { MfaSettingsRoute: Component } = await import(
            "./routes/mfa/mfa"
          );
          return { Component };
        },
      },
      {
        path: "tokens",
        async lazy() {
          const { TokensSettingsRoute: Component } = await import(
            "./routes/tokens/tokens"
          );
          return { Component };
        },
      },
      {
        path: "users",
        async lazy() {
          const { UsersSettingsRoute: Component } = await import(
            "./routes/users/users"
          );
          return { Component };
        },
      },
    ],
  },
];
