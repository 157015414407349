import Spinner from "$src/assets/img/spinner.svg?react";
import { cx } from "$src/lib/utils";
import { type ComponentProps } from "react";
import { Link, type LinkProps } from "react-router-dom";

import styles from "./button.module.css";

export type ButtonProps = {
  loading?: boolean;
  href?: string;
  label: string;
  icon?: any;
  theme?: "primary" | "secondary" | "ghost";
  disabled?: boolean;
} & ComponentProps<"button"> &
  Partial<LinkProps>;

export const Button = ({
  loading,
  label,
  theme = "primary",
  href,
  icon: Icon,
  className,
  ...props
}: ButtonProps) => {
  const El = href ? Link : "button";

  return (
    <El
      to={href}
      className={cx(
        styles.button,
        styles[theme],
        loading && styles.loading,
        className,
      )}
      {...(props as any)}
    >
      {loading ? (
        <Spinner className={styles.spinner} data-testid="loading-spinner" />
      ) : (
        <>
          {Icon && <Icon className={styles.icon} />}
          {label}
        </>
      )}
    </El>
  );
};
