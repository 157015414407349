import { type RouteObject } from "react-router-dom";

export const statementsRoutes: RouteObject["children"] = [
  {
    async lazy() {
      const { StatementsLayout: Component } = await import("./layout");
      return { Component };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { StatementsOverviewRoute: Component } = await import(
            "./routes/overview"
          );
          return { Component };
        },
      },
      {
        path: "comparison/:statementId?",
        async lazy() {
          const { StatementsComparisonRoute: Component } = await import(
            "./routes/comparison/comparison"
          );
          return { Component };
        },
      },
    ],
  },
];
