import { z } from "zod";

import { Nat } from "./nat";

export const EpochMillis = Nat.brand("EpochMillis");
export type EpochMillis = z.infer<typeof EpochMillis>;

export const EpochSeconds = Nat.brand("EpochSeconds");
export type EpochSeconds = z.infer<typeof EpochSeconds>;

export const IntervalMillis = Nat.brand("IntervalMillis");
export type IntervalMillis = z.infer<typeof IntervalMillis>;

export const IntervalSeconds = Nat.brand("IntervalSeconds");
export type IntervalSeconds = z.infer<typeof IntervalSeconds>;
