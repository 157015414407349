import { z } from "zod";

import { IntoNat } from "@tracksuit/fundamental-types";

import { StateCode, StateName } from "./demographic";

export const TrackerId = IntoNat.brand("TrackerId");

export type TrackerId = z.infer<typeof TrackerId>;

export const TrackerName = z.string().brand("TrackerName");

export type TrackerName = z.infer<typeof TrackerName>;

export const TrackerLevel = z.enum(["NATIONAL", "REGIONAL"]);

export type TrackerLevel = z.infer<typeof TrackerLevel>;

export const Tracker = z.object({
  id: TrackerId,
  name: TrackerName,
  stateCode: StateCode.optional().nullable(),
  stateName: StateName.optional().nullable(),
});

export type Tracker = z.infer<typeof Tracker>;
