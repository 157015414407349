import { type RouteObject } from "react-router-dom";

export const conversionRoutes: RouteObject["children"] = [
  {
    async lazy() {
      const { ConversionLayout: Component } = await import("./layout");
      return { Component };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { ConversionOverviewRoute: Component } = await import(
            "./routes/overview/overview"
          );
          return { Component };
        },
      },
      {
        path: "timeline",
        async lazy() {
          const { ConversionTimelineLayout: Component } = await import(
            "./routes/timeline/layout"
          );
          return { Component };
        },
        children: [
          {
            index: true,
            async lazy() {
              const { ConversionTimelineOverviewRoute: Component } =
                await import("./routes/timeline/overview/overview");
              return { Component };
            },
          },
          {
            path: "metric/:metric?",
            async lazy() {
              const { ConversionTimelineMetricRoute: Component } = await import(
                "./routes/timeline/metric/metric"
              );
              return { Component };
            },
          },
        ],
      },
      {
        path: "comparison/:metric?",
        async lazy() {
          const { ConversionComparisonRoute: Component } = await import(
            "./routes/comparison/comparison"
          );
          return { Component };
        },
      },
    ],
  },
];
