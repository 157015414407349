import { useAmplifyAuth } from "$src/hooks/use-amplify-auth";
import { AnalyticsEvents, useAnalytics } from "$src/hooks/use-analytics";
import { initRaygun, raygun } from "$src/lib/raygun";
import { useConfig } from "$src/stores/use-config";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { Outlet, useLocation } from "react-router-dom";

import { apiQueryClient, trpcHttp } from "../lib/api";

export const App = () => {
  const { ready: authReady } = useAmplifyAuth();
  const [configReady, initConfig] = useConfig((s) => [s.ready, s.init]);
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          throwOnError: true,
          staleTime: Infinity,
        },
      },
    }),
  );
  const trpcClient = apiQueryClient.createClient({
    links: [trpcHttp()],
  });
  const analytics = useAnalytics();
  const { pathname } = useLocation();
  const [raygunReady, setRaygunReady] = useState(false);

  useEffect(() => {
    initRaygun?.().then(() => setRaygunReady(true));
    initConfig();
  }, []);

  useEffect(() => {
    const startTime = new Date().getTime();

    return () => {
      analytics?.track(AnalyticsEvents.SessionLength, {
        duration: (new Date().getTime() - (startTime ?? 0)) / 1000,
      });
    };
  }, [analytics]);

  useEffect(() => {
    analytics?.track(AnalyticsEvents.PageView, {
      pathname,
    });

    raygunReady &&
      raygun?.("trackEvent", {
        type: "pageView",
        path: pathname,
      });
  }, [analytics, pathname, raygunReady]);

  return (
    <apiQueryClient.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <SkeletonTheme
          baseColor="var(--color-grey-100)"
          highlightColor="var(--color-off-white-opacity-dark)"
          borderRadius="var(--radius-full)"
        >
          {authReady && raygunReady && configReady && <Outlet />}
        </SkeletonTheme>
      </QueryClientProvider>
    </apiQueryClient.Provider>
  );
};
