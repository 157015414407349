import { useAvailableFilters } from "$src/queries/use-available-filters";
import { omitBy } from "lodash-es";
import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import {
  dataRepresentation,
  date,
  dateComparisonPeriod,
  naturalNumber,
  questionType,
} from "@tracksuit/frontend/schemas";

import { useFunnelQuestions } from "../hooks/use-funnel-questions";
import { useRequiredDatesCount } from "../hooks/use-required-dates-count";
import { useShallowUpdateSearchParams } from "../hooks/use-shallow-update-search-params";
import { COMPETITOR_AVERAGE_ID } from "../lib/consts";
import { raygun } from "../lib/raygun";
import { useAccount } from "./use-account";

type FiltersStore = {
  filters: z.infer<typeof filtersSchema>;
  ready: boolean;
  init(): void;
  set(filters: Partial<z.infer<typeof filtersSchema>>): void;
  reset(): void;
};

const demographicFilter = z.object({
  id: z.string(),
  filter: z.string(),
});
export type DemographicFilter = z.infer<typeof demographicFilter>;

const filtersSchema = z.object({
  demographics: demographicFilter.array(),
  // Sample period, used for most views
  datePeriod: z.object({
    start: date.optional(),
    end: date.optional(),
    comparisonPeriod: dateComparisonPeriod.optional(),
  }),
  // Range of dates, used for timelines
  dateRange: z.object({
    start: date.optional(),
    end: date.optional(),
  }),
  question: questionType,
  questionList: questionType.array(),
  brandId: naturalNumber.optional(),
  brandIdList: naturalNumber.array(),
  dataRepresentation: dataRepresentation,
  hasNoPopulationData: z.boolean(),
});

const INITIAL_STATE: z.infer<typeof filtersSchema> = {
  demographics: [],
  datePeriod: {
    start: undefined,
    end: undefined,
    comparisonPeriod: "preceding" as const,
  },
  dateRange: {
    start: undefined,
    end: undefined,
  },
  question: "PROMPTED_AWARENESS",
  questionList: ["PROMPTED_AWARENESS", "CONSIDERATION", "USAGE", "PREFERENCE"],
  brandId: undefined,
  brandIdList: [],
  dataRepresentation: "percentage" as z.infer<typeof dataRepresentation>,
  hasNoPopulationData: false,
};

export const useFilters = createWithEqualityFn<FiltersStore>()(
  (set) => ({
    filters: INITIAL_STATE,
    ready: false,
    set(filters) {
      try {
        filtersSchema.partial().parse(filters);
        set((s) => ({
          ...s,
          filters: {
            ...s.filters,
            ...filters,
          },
        }));
      } catch (err) {
        raygun?.("send", err);
      }
    },
    init() {
      set(() => ({ ready: true }));
    },
    reset() {
      set({ filters: INITIAL_STATE, ready: false });
    },
  }),
  shallow,
);

export const useInitFilters = () => {
  const account = useAccount((s) => s.active);
  const { availableFilters } = useAvailableFilters();
  const [setFilters, filtersReady, initFilters] = useFilters((s) => [
    s.set,
    s.ready,
    s.init,
    s.reset,
  ]);
  const funnelQuestions = useFunnelQuestions({
    unprompted: true,
    investigation: true,
    category: true,
  });
  const sufficientDates = useRequiredDatesCount(3);
  const defaultFilters = useMemo(() => {
    if (!availableFilters.dates || !account || !funnelQuestions) {
      return;
    }

    return {
      brandId: account?.brandId,
      brandIdList: [
        account?.brandId,
        COMPETITOR_AVERAGE_ID,
        ...account.competitors.map((competitor) => competitor.id),
      ],
      datePeriod: {
        start: sufficientDates
          ? availableFilters.dates[2]
          : availableFilters.dates[availableFilters.dates.length - 1],
        end: availableFilters.dates[0],
        comparisonPeriod: "preceding" as const,
      },
      questionList: funnelQuestions,
      dateRange: {
        start:
          availableFilters.dates[
            Math.min(availableFilters.dates.length, 12) - 1
          ],
        end: availableFilters.dates[0],
      },
    };
  }, [availableFilters.dates, funnelQuestions, account]);

  useEffect(() => {
    if (filtersReady || !defaultFilters) {
      return;
    }

    setFilters(defaultFilters);
    initFilters();
  }, [defaultFilters, filtersReady]);
};

export const useReflectFiltersToUrl = () => {
  const account = useAccount((s) => s.active);
  const { availableFilters } = useAvailableFilters();
  const funnelQuestions = useFunnelQuestions({
    investigation: true,
    unprompted: true,
    category: true,
  });
  const [searchParams] = useSearchParams();
  const [setFilters, filtersReady] = useFilters((s) => [s.set, s.ready]);
  const {
    demographics,
    dateRange,
    datePeriod,
    question,
    questionList,
    brandId,
    brandIdList,
    dataRepresentation,
  } = useFilters((s) => s.filters);
  const getDemographicFilterById = (id: string) => {
    const allFilters = availableFilters.demographics
      .map(({ filters }) => filters)
      .flat();
    return allFilters.find((f) => f.id === id);
  };
  const getDemographicObject = (filterIdOrLabel: string) => {
    return availableFilters?.demographics?.find((f) =>
      f.filters.some((filter) => {
        return (
          filter.id === filterIdOrLabel || filter.filter === filterIdOrLabel
        );
      }),
    );
  };

  const validateFilters = useCallback(
    (filters: Partial<z.infer<typeof filtersSchema>>) => {
      if (!account || !availableFilters) {
        return filters;
      }

      const brandIds = [
        account?.brandId,
        COMPETITOR_AVERAGE_ID,
        ...(account?.competitors.map(({ id }) => id) ?? []),
      ];
      const validateField = (
        key: keyof typeof filters,
        validator: (filter: any) => boolean,
      ) => {
        if (!filters[key]) {
          return undefined;
        }

        return validator(filters[key]) ? filters[key] : undefined;
      };

      return omitBy(
        {
          ...filters,
          brandId: validateField("brandId", (brandId) =>
            brandIds.includes(brandId),
          ),
          brandIdList: validateField("brandIdList", (brandIdList) =>
            brandIdList.every((id: any) => brandIds.includes(id)),
          ),
          questionList: validateField("questionList", (questionList) =>
            questionList.every((q: any) => funnelQuestions.includes(q)),
          ),
          demographics: validateField("demographics", (demographics) =>
            demographics.every(({ id }: any) => !!getDemographicFilterById(id)),
          ),
          dateRange: validateField(
            "dateRange",
            (dateRange) =>
              !!availableFilters.dates?.includes(dateRange.start as string),
          ),
          datePeriod: validateField(
            "datePeriod",
            (datePeriod) =>
              !!availableFilters.dates?.includes(datePeriod.start as string),
          ),
        },
        (p) => (Array.isArray(p) ? !p.length : !p),
      ) as typeof filters;
    },
    [availableFilters, account],
  );

  const searchParamsFilters = useMemo(() => {
    if (!availableFilters.demographics) {
      return;
    }

    const params = omitBy(
      {
        demographics: searchParams
          .getAll("demographics")
          ?.map(getDemographicFilterById),
        question: searchParams.get("question"),
        dataRepresentation: searchParams.get("dataRepresentation"),
        brandId: Number(searchParams.get("brandId")),
        brandIdList: searchParams
          .getAll("brandIdList")
          ?.map((id) => Number(id)),
        questionList: searchParams.getAll("questionlist"),
        datePeriod:
          searchParams.get("periodStartDate") &&
          searchParams.get("periodEndDate")
            ? {
                start: searchParams.get("periodStartDate"),
                end: searchParams.get("periodEndDate"),
                comparisonPeriod:
                  searchParams.get("comparisonPeriod") ?? "preceding",
              }
            : undefined,
        dateRange:
          searchParams.get("rangeStartDate") && searchParams.get("rangeEndDate")
            ? {
                start: searchParams.get("rangeStartDate"),
                end: searchParams.get("rangeEndDate"),
              }
            : undefined,
        hasNoPopulationData: searchParams.getAll("demographics").length
          ? !getDemographicObject(searchParams.getAll("demographics")[0]!)
              ?.supportsPopulation
          : false,
      },
      (p) => (Array.isArray(p) ? !p.length : !p),
    );
    const safeParams = filtersSchema.partial().safeParse(params);

    if (!safeParams.success) {
      console.warn(
        "search params include invalid filters: ",
        safeParams.error.issues,
      );
      raygun?.(
        "send",
        new Error(
          `search params include invalid filters: ${safeParams.error.issues
            .flat()
            .join(", ")}`,
        ),
      );
      return;
    }

    const validatedParams = validateFilters(safeParams.data);

    return validatedParams;
  }, [searchParams, availableFilters.demographics]);

  useEffect(() => {
    if (!filtersReady || !searchParamsFilters) {
      return;
    }

    setFilters(searchParamsFilters);
  }, [searchParamsFilters, filtersReady]);

  useShallowUpdateSearchParams(
    "demographics",
    demographics.map(({ id }) => id),
  );
  useShallowUpdateSearchParams("rangeStartDate", dateRange.start);
  useShallowUpdateSearchParams("rangeEndDate", dateRange.end);
  useShallowUpdateSearchParams("periodStartDate", datePeriod.start);
  useShallowUpdateSearchParams("periodEndDate", datePeriod.end);
  useShallowUpdateSearchParams("comparisonPeriod", datePeriod.comparisonPeriod);
  useShallowUpdateSearchParams("question", question);
  useShallowUpdateSearchParams("questionList", questionList);
  useShallowUpdateSearchParams("brandId", brandId);
  useShallowUpdateSearchParams(
    "brandIdList",
    brandIdList.map((id) => id),
  );
  useShallowUpdateSearchParams("dataRepresentation", dataRepresentation);
};
