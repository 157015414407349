import { z } from "zod";

export const FeaturesConfig = z.object({
  statementsEnabled: z.boolean(),
  profileEnabled: z.boolean(),
  imageryEnabled: z.boolean(),
  investigationEnabled: z.boolean(),
  unpromptedEnabled: z.boolean(),
  usageEnabled: z.boolean(),
  includeIncompleteMonths: z.boolean(),
});

export type FeaturesConfig = z.infer<typeof FeaturesConfig>;
