import { z } from "zod";

import { PosInt } from "./pos-int";

export const Port = PosInt.max(65535).brand("Port").describe("Port number");

export type Port = z.infer<typeof Port>;

export const IntoPort = z.coerce.number().pipe(Port);

export type IntoPort = z.infer<typeof IntoPort>;
