import { z } from "zod";

import { questionSubType, questionType } from "./questionType";

export const surveyQuestion = z.object({
  questionText: z.string(),
  questionType: questionType.optional(),
  questionSubType: questionSubType.optional(),
  options: z
    .object({
      optionText: z.string(),
      qualifyingOption: z.boolean().optional(),
      hidden: z.union([z.boolean(), z.null()]).optional(),
    })
    .array()
    .optional(),
});

export type SurveyQuestion = z.infer<typeof surveyQuestion>;
