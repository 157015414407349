import { z } from "zod";

import { IntoNat } from "@tracksuit/fundamental-types";

import { BrandCategoryGeographyId } from "./brand-category-geography";

export const BrandId = IntoNat.brand("BrandId");

export type BrandId = z.infer<typeof BrandId>;

export const BrandName = z.string().brand("BrandName");

export type BrandName = z.infer<typeof BrandName>;

export const Brand = z.object({
  id: BrandId,
  name: BrandName,
  brandCategoryGeographyId: BrandCategoryGeographyId,
});

export type Brand = z.infer<typeof Brand>;
