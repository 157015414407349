import { z } from "zod";

import { Account } from "./account";

export const UserId = z.string().min(0).brand("UserId");

export type UserId = z.infer<typeof UserId>;

export const UserRole = z.enum(["super_admin", "admin", "user"]);

export type UserRole = z.infer<typeof UserRole>;

export type UserLike = {
  isClientAdmin: boolean;
  isAdmin: boolean;
};

export const computeRole = (input: UserLike): UserRole => {
  if (input.isClientAdmin) {
    return "admin";
  }
  if (input.isAdmin) {
    return "super_admin";
  }
  return "user";
};

export const User = z.object({
  userId: UserId,
  email: z.string(),
  role: UserRole,
  status: z.string(),
  accounts: z.array(Account),
});

export type User = z.infer<typeof User>;

export const UserAccessToken = z.object({
  accessToken: z.string(),
  expiresAt: z.instanceof(Date),
});

export type UserAccessToken = z.infer<typeof UserAccessToken>;
