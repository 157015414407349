import { z } from "zod";

export const SurveyId = z.string().brand("SurveyId");

export type SurveyId = z.infer<typeof SurveyId>;

export const SampleSizeQuality = z.enum([
  "INSUFFICIENT",
  "STRONG",
  "INDICATIVE",
]);

export type SampleSizeQuality = z.infer<typeof SampleSizeQuality>;
