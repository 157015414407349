import { z } from "zod";

export const Age = z.string().brand("Age");

export type Age = z.infer<typeof Age>;

export const Gender = z.string().brand("Gender");

export type Gender = z.infer<typeof Gender>;

export const Location = z.string().brand("Location");

export type Location = z.infer<typeof Location>;

export const StateId = z.number().brand("StateId");

export type StateId = z.infer<typeof StateId>;

export const StateCode = z.string().brand("StateCode");

export type StateCode = z.infer<typeof StateCode>;

export const StateName = z.string().brand("StateName");

export type StateName = z.infer<typeof StateName>;

export const Income = z.string().brand("Income");

export type Income = z.infer<typeof Income>;
