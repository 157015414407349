import { z } from "zod";

export const Stringified = z
  .string({ invalid_type_error: "Expected JSON string" })
  .transform((input, ctx) => {
    try {
      return JSON.parse(input);
    } catch (e: any) {
      ctx.addIssue({
        code: "custom",
        message: `Invalid JSON string: ${e.message}`,
      });
      return z.NEVER;
    }
  });

export const FromStringified = z.union([Stringified, z.any()]);
