import { z } from "zod";

export const ProfileQuestionType = z.enum([
  "PROMPTED_AWARENESS",
  "UNPROMPTED_AWARENESS",
  "PREFERENCE",
  "USAGE",
  "CONSIDERATION",
  "INVESTIGATION",
]);

export type ProfileQuestionType = z.infer<typeof ProfileQuestionType>;
