/* v8 ignore start */
// This hook just sets up Amplify, nothing to test our end
import { amplifyConfig } from "$src/lib/auth";
import type { CognitoUser } from "@aws-amplify/auth";
import type { AuthOptions } from "@aws-amplify/auth/lib-esm/types";
import { Auth, Hub } from "aws-amplify";
import { useEffect, useState } from "react";

import { useConfig } from "../stores/use-config";

export type AuthSession = Awaited<ReturnType<typeof Auth.currentSession>>;
export type AuthUser = CognitoUser & {
  attributes: { [key: string]: string };
  username: string;
};

export const useAmplifyAuth = () => {
  const appConfig = useConfig((s) => s.config);
  const [user, setUser] = useState<AuthUser | null>();
  const [ready, setReady] = useState(false);
  const [session, setSession] = useState<AuthSession | null>();
  const [config, setConfig] = useState<AuthOptions>();

  async function tryAuthenticate() {
    await Auth.Credentials.get();

    try {
      const user = (await Auth.currentAuthenticatedUser()) as AuthUser;
      const session = await Auth.currentSession();
      setUser(user);
      setSession(session);
    } catch {
      setUser(null);
      setSession(null);
    }
  }

  useEffect(() => {
    if (!appConfig) {
      return;
    }

    if (!config) {
      Hub.listen("auth", ({ payload }) => {
        switch (payload.event) {
          case "configured":
            tryAuthenticate().then(() => setReady(true));
            break;
          case "signIn":
          case "tokenRefresh":
            tryAuthenticate();
            break;
          case "signOut":
            setSession(null);
            setUser(null);
            break;
        }
      });

      setConfig(Auth.configure(amplifyConfig(appConfig)));
    }
  }, [config, appConfig]);

  return { user, session, ready };
};
/* v8 ignore end */
