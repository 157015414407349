import { z } from "zod";

import { IntoWaveDate } from "./wave-date";

export const DatePeriod = z.object({
  waveStartDate: IntoWaveDate,
  waveEndDate: IntoWaveDate,
});

export type DatePeriod = z.infer<typeof DatePeriod>;
