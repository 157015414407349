import Logo from "$src/assets/img/logo.svg?react";
import splash from "$src/assets/img/splash.svg?url";
import { useAmplifyAuth } from "$src/hooks/use-amplify-auth";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import styles from "./layout.module.css";

export function AuthLayout() {
  const { ready, session } = useAmplifyAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (ready && session) {
      navigate("/", {
        replace: true,
      });
    }
  }, [ready, session]);

  return (
    <main className={styles.page}>
      <div className={styles.splash}>
        <img className={styles["splash-img"]} src={splash} alt="Splash image" />
      </div>
      <div className={styles.content}>
        <div className={styles["content-inner"]}>
          <Logo className={styles.logo} />
          <div className={styles.children}>
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  );
}
