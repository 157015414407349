import { useAccount } from "$src/stores/use-account";
import { useMemo } from "react";

import type { QuestionType } from "@tracksuit/frontend/schemas";

export const FUNNEL_QUESTIONS = [
  "UNPROMPTED_AWARENESS",
  "PROMPTED_AWARENESS",
  "CONSIDERATION",
  "INVESTIGATION",
  "USAGE",
  "PREFERENCE",
];

export function useFunnelQuestions({
  category = false,
  unprompted = true,
  investigation = true,
}) {
  const account = useAccount((s) => s.active);
  const questions = useMemo<QuestionType[]>(
    () =>
      (
        [
          ...(category ? ["QUALIFYING"] : []),
          ...FUNNEL_QUESTIONS,
        ] as QuestionType[]
      ).filter((question) => {
        switch (question) {
          case "UNPROMPTED_AWARENESS":
            return unprompted && account?.featuresConfig.unpromptedEnabled;
          case "INVESTIGATION":
            return (
              investigation && account?.featuresConfig.investigationEnabled
            );
          case "USAGE":
            return account?.featuresConfig.usageEnabled;
          default:
            return true;
        }
      }),
    [
      category,
      unprompted,
      investigation,
      account?.featuresConfig.investigationEnabled,
      account?.featuresConfig.unpromptedEnabled,
      account?.featuresConfig.usageEnabled,
    ],
  );

  return questions;
}
