import { type RouteObject } from "react-router-dom";

export const funnelRoutes: RouteObject["children"] = [
  {
    async lazy() {
      const { FunnelLayout: Component } = await import("./layout");
      return { Component };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { FunnelOverviewRoute: Component } = await import(
            "./routes/overview/overview"
          );
          return { Component };
        },
      },
      {
        path: "comparison/:question?",
        async lazy() {
          const { FunnelComparisonRoute: Component } = await import(
            "./routes/comparison/comparison"
          );
          return { Component };
        },
      },
      {
        path: "timeline",
        async lazy() {
          const { FunnelTimelineLayout: Component } = await import(
            "./routes/timeline/layout"
          );
          return { Component };
        },
        children: [
          {
            index: true,
            async lazy() {
              const { FunnelTimelineOverviewRoute: Component } = await import(
                "./routes/timeline/overview/overview"
              );
              return { Component };
            },
          },
          {
            path: "metric/:question?",
            async lazy() {
              const { FunnelTimelineMetricRoute: Component } = await import(
                "./routes/timeline/metric/metric"
              );
              return { Component };
            },
          },
        ],
      },
    ],
  },
];
