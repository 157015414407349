import { z } from "zod";

export const IntoBoolean = z.union([
  z.boolean(),
  z
    .string()
    .transform((it) => it.toLowerCase())
    .transform((value) => !["false", "no", "0"].includes(value)),
]);

export type IntoBoolean = z.infer<typeof IntoBoolean>;
