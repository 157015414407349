import { z } from "zod";

export const DemographicFilters = z
  .string()
  .transform((s) => [s])
  .or(z.array(z.string()))
  .transform((arr) => (arr.length === 0 ? ["TOTAL"] : arr));

export type DemographicFilters = z.infer<typeof DemographicFilters>;

export const Demographic = z.string().brand("Demographic");
export type Demographic = z.infer<typeof Demographic>;

export const FilterTypeId = z.string().brand("FilterTypeId");

export type FilterTypeId = z.infer<typeof FilterTypeId>;

export const DemographicFilterIds = FilterTypeId.transform((s) => [s]).or(
  z.array(FilterTypeId),
);

export type DemographicFilterIds = z.infer<typeof DemographicFilterIds>;
