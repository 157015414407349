import { z } from "zod";

import { IntoNat } from "@tracksuit/fundamental-types";

export const StdQuestion = z.enum([
  "QUALIFYING",
  "UNPROMPTED_AWARENESS",
  "PROMPTED_AWARENESS",
  "CONSIDERATION",
  "INVESTIGATION",
  "USAGE",
  "PREFERENCE",
  "BRAND_ASSOCIATIONS",
  "DEMOGRAPHIC",
  "CUSTOM_QUESTION",
]);

export type StdQuestion = z.infer<typeof StdQuestion>;

export const SurveyQuestion = z.string();
export type SurveyQuestion = z.infer<typeof SurveyQuestion>;

export const AdjustedByQuestion = StdQuestion.extract([
  "QUALIFYING",
  "PROMPTED_AWARENESS",
  "CONSIDERATION",
  "USAGE",
  "PREFERENCE",
  "INVESTIGATION",
]);

export type AdjustedByQuestion = z.infer<typeof AdjustedByQuestion>;

export const QuestionSubType = z.string().brand("QuestionSubType");

export type QuestionSubType = z.infer<typeof QuestionSubType>;

export const QuestionId = IntoNat.brand("QuestionId");

export type QuestionId = z.infer<typeof QuestionId>;

export const QuestionOptionId = IntoNat.brand("QuestionOptionId");

export type QuestionOptionId = z.infer<typeof QuestionOptionId>;

export const FunnelMetricStdQuestion = StdQuestion.extract([
  "UNPROMPTED_AWARENESS",
  "PROMPTED_AWARENESS",
  "CONSIDERATION",
  "INVESTIGATION",
  "USAGE",
  "PREFERENCE",
]);

export type FunnelMetricStdQuestion = z.infer<typeof FunnelMetricStdQuestion>;

export const QuestionText: Partial<Record<StdQuestion, string>> = {
  PROMPTED_AWARENESS: "awareness",
  UNPROMPTED_AWARENESS: "unprompted awareness",
  CONSIDERATION: "consideration",
  USAGE: "usage",
  PREFERENCE: "preference",
  INVESTIGATION: "consumer interest",
  QUALIFYING: "amount of people who qualify for the category",
};
