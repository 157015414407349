import { apiQueryClient } from "$src/lib/api";
import { useAccount } from "$src/stores/use-account";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import type { BFFInput, BFFOutput } from "@tracksuit/bff/trpc";

import { useAdminSettings } from "../stores/use-admin-settings";

export const useAvailableFilters = (hideEmptyPopFilters?: boolean) => {
  const accountBrand = useAccount((s) => s.active);
  const includeIncompleteMonths = useAdminSettings(
    (s) => s.settings.includeIncompleteMonths,
  );
  const { pathname } = useLocation();
  const [demographics, setDemographics] = useState<
    NonNullable<BFFOutput["filters"]["get"]["demographics"]>
  >([]);
  const {
    incomeFilter,
    ethnicityFilter,
    householdStatusFilter,
    usRegionFilter,
    personasFilter,
    countyFilter,
    auRegionalFilters,
  } = useFlags();
  const namespace: BFFInput["filters"]["get"]["namespace"] = useMemo(
    () => (pathname.startsWith("/statements") ? "statements" : "funnel"),
    [pathname],
  );
  const namespaceValid = useMemo(
    () =>
      namespace === "funnel"
        ? true
        : accountBrand?.featuresConfig.statementsEnabled,
    [namespace, accountBrand],
  );
  const { data, isLoading: loading } = apiQueryClient.filters.get.useQuery(
    {
      accountBrandId: Number(accountBrand?.accountBrandId),
      includeIncompleteMonths: !!includeIncompleteMonths,
      namespace,
    },
    {
      enabled: !!namespaceValid && !!accountBrand,
    },
  );

  useEffect(() => {
    setDemographics(
      data?.demographics?.length
        ? data.demographics
            ?.filter((f) => {
              switch (f.label) {
                case "Region":
                case "Region - New Zealand":
                case "Region - Australia":
                case "Region - United States":
                case "Census division":
                case "Region - United Kingdom":
                case "Region - Canada":
                case "Region - Auckland":
                case "Gender":
                case "Age":
                case "State":
                case "DMA":
                case "Residence":
                case "Custom filter":
                case "Sub-categories":
                case "Consumer type":
                  return true;
                case "Income":
                case "Household income":
                  return incomeFilter;
                case "Household Status":
                case "Household status":
                  return householdStatusFilter;
                case "Ethnicity":
                  return ethnicityFilter;
                case "Region - US":
                  return usRegionFilter;
                case "Personas":
                  return personasFilter;
                case "County":
                  return countyFilter;
                case "Metro - Australia":
                case "State - Australia":
                  return auRegionalFilters;
                default:
                  return false;
              }
            })
            .filter((f) =>
              hideEmptyPopFilters === true ? f.supportsPopulation : true,
            )
        : [],
    );
  }, [
    incomeFilter,
    ethnicityFilter,
    householdStatusFilter,
    usRegionFilter,
    personasFilter,
    data?.demographics,
  ]);

  return {
    availableFilters: {
      dates: data?.dates,
      demographics,
      brands: [
        { id: accountBrand?.brandId, name: accountBrand?.brandName },
        ...(accountBrand?.competitors ?? []),
      ],
    },
    loading,
  };
};
