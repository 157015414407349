/* v8 ignore start */
import { loadConfig } from "$src/stores/use-config";
import type { RaygunV2 } from "raygun4js";

const getRaygun = async () => {
  const { default: raygun } =
    typeof window !== "undefined"
      ? await import("raygun4js")
      : { default: undefined };

  return raygun;
};

export const raygun = ((...args: any): Promise<any> => {
  return getRaygun?.().then((rg) => (rg as any)?.(...args));
}) as unknown as Maybe<RaygunV2>;

export const initRaygun = async () => {
  const raygun = await getRaygun();
  const config = await loadConfig();

  raygun?.("apiKey", config?.RAYGUN_API_KEY as string);
  raygun?.("enableCrashReporting", true);
  raygun?.("enablePulse", true);
  raygun?.("options", {
    excludedHostnames: ["localhost"],
    captureUnhandledRejections: true,
    ignore3rdPartyErrors: true,
  });
  raygun?.("withTags", [(config?.ENVIRONMENT as string) ?? ""]);
  return raygun;
};
/* v8 ignore end */
