import { useEffect, useState } from "react";

import { trailblazers } from "@tracksuit/schemas/shared";

import { useAmplifyAuth } from "../use-amplify-auth";

export const useIsTrailblazer = () => {
  const { user } = useAmplifyAuth();
  const [isTrailblazer, setIsTrailblazer] = useState<boolean>();

  useEffect(() => {
    if (user) {
      setIsTrailblazer(
        trailblazers.includes(user.attributes.email?.toLowerCase() ?? ""),
      );
    }
  }, [user]);

  return isTrailblazer;
};
